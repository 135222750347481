
export default class EventLogClass {


    main
    modal
    eventHandlers = []

    constructor(){

    }

    init(){
        this.main = $('<div>')

        this.modal = new CS.Modal({
            title: 'Event Log',
            body: this.main
        })
        this.startListening()

        this.eventHandlers.push(this.jobEventHandlers)
    }

    show(){


        this.modal.show()

    }

    handleEvent(eventName, eventData) {

        this.eventHandlers.forEach(eventHandler => {
            eventHandler(eventName, eventData)
        })

    }

    jobEventHandlers(eventName, eventData){
        console.log(eventName, eventData)
        if(eventName === '.job.step.new'){
            CS.EventLog.addLine(eventData)
        }else if(eventName === '.job.step.update'){
            CS.EventLog.updateLine(eventData)
        }else{
            CS.EventLog.main.append(
                $('<div>').html(eventData.txt)
            )
        }

    }

    addLine(data){
        let uid = 'line-' + data.job + '-' + data.step
        let cont = $('<div>')
            .attr('class', 'line '+uid)
            .append(
            $('<div>')
                .attr('class', 'loading fa-solid fa-spinner fa-spin'),
            data.txt
        )
        this.main.append(cont)
    }

    updateLine(data){
        let uid = '.line-' + data.job + '-' + data.step
        let cls = ''
        if(data.status === 'success'){
            cls = 'fa-solid fa-check text-success'
        }
        if(data.status === 'error'){
            cls = 'fa-solid fa-xmark text-danger'
        }
        $('.loading', uid).attr('class', cls)
    }

    startListening() {
        window.Echo.channel('maintenanceChannel')
            .listenToAll((a,b) => { this.handleEvent(a,b) })
    }



}
