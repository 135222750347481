
export function renderSpinner(text = 'Loading'){
    return $('<div>')
        .addClass('modal-loader')
        .append(
            $('<div>')
                .attr('class', 'spinner-border')
                .append("<span class='visually-hidden'>Loading</span>"),
            $('<div>')
                .html(text)
                .addClass('fw-bolder fs-5')
        )
}
