import AccordionClass from "./html/accordion.js";
import AddBidClass from "./addBid.js";

export default class {

    Acc
    ctx
    AddBid
    constructor() {
        // console.log('Started Emails')
    }

    init(data){
        this.ctx = $('#EmailsContainer').html('??').addClass('container-fluid');

        this.data =  data


        console.log('Emails Initiated', data)
        this.FoldersList = $('#FolderList')
        this.MessageList = $('#MessageList')
        this.MessageView = $('#MessageView')
        this.SideBar = $('#SideBar')



        this.Acc = new AccordionClass()

        this.renderAccount(data.pre??[])
        // this.fetchAccount()
    }

    fetchMsg(id, obj){
        obj.attr('srcdoc','Loading Email')

        CS.ajaxCall({
            url: route('emails.get.msg'),
            data: {'id': id},
        }, (response) => {
            obj.attr('srcdoc',response.bodies.html)
        })
    }
    fetchAccount(){
        this.ctx.html(CS.renderSpinner('Loading Emails'))
        CS.ajaxCall({
            url: route('getAccount'),
        }, (response) => {
            // this.renderAccount(response)
            window.location.reload()

        })
    }

    renderAccount(messages){
        // $(data.folderList).each((key, item) => {
        // let item = 'INBOX'
        // let messages = data.folders[item]

        // let listing = this.addFolderListing(item)
        $(messages).each((key, msg) => {
            // console.log('render', key,msg)
            let cont = $('<div>').addClass('msg-'+key)
            let importBid = $('<div>').addClass('btn btn-success').html('Import')
                .on('click', ()=> {
                    ab.save((r)=>{
                        if(r.failed){
                            return
                        }
                        //Cleanup
                        ab = new AddBidClass()
                        cont.html()
                        $('.'+msgAccInd).remove()
                        CS.ajaxCall({
                            url: route('emails.import.msg'),
                            data: {'id': msg.uid},
                        }, (response) => {})
                    })
                })

            let checkAI = $('<div>').addClass('btn btn-warning').html('AI')
                .on('click', ()=>{
                    let n = CS.notif('warning','Asking AI to auto-complete...')
                    CS.ajaxCall({
                        url: route('emails.get.ai'),
                        data: {'id': msg.uid},
                    }, (response) => {

                        n.hide()
                        CS.notif('success', 'Done')
                        for(let name in response){
                            $('input[name="'+name+'"]', cont).val(response[name])
                        }

                        $('select[name="contractor"]', cont)[0].selectize.addItem(response['ContractorID'])

                    })
                })
            // listing.append(this.addListMessage(msg))

            let msgBox = $('<iframe>').addClass('border border-1 border-gray previewTabContainer w-100 h-100')

            let ab = new AddBidClass()



            let title = $('<div>').addClass('row w-100 m-0')
                .append(
                    $('<div>').addClass('col-1 badge badge-warning bg-warning text-dark ms-auto text-truncate').html(msg.contractor),
                    $('<div>').addClass('col text-truncate').html(msg.subject),
                    $('<div>').addClass('col-1  badge text-dark bg-info  text-truncate mx-2').html(msg.from),
                    $('<div>').addClass('col-1  badge  bg-dark  text-truncate').html(msg.platform),
                    $('<div>').addClass('col-1 badge bg-light text-dark ms-auto').html(msg.dateFormated)
                )
            // let end =
            // let titleCont = $('<div>').addClass('hstack gap-3 w-100')
            //     .append(title)

            let msgAccInd = this.Acc.add(title,cont, {'onOpen': () => {
                if(msgBox.attr('data-loaded') !== 'yes'){

                    msgBox.attr('data-loaded', 'yes')
                    this.fetchMsg(msg.uid, msgBox)

                    ab.init(this.data, {
                        'context': $('#EmailsContainer'),
                        'container': cont,
                        'extraTabs': [{title: 'Email', content: msgBox}],
                        'buttons' : [checkAI, importBid],
                        'values': msg
                    })
                }
                }})
        })
        this.ctx.html(this.Acc.html())
        this.ctx.prepend($('<div>').addClass('btn btn-info').html('RESET')
            .on('click', ()=> {
                this.fetchAccount()
            }))

    }


    // addFolderListing(data){
    //     let cont = $('<div>')
    //         .addClass('folder-listing')
    //
    //     this.MessageList.append(cont)
    //
    //     return cont
    // }
    //
    // addFolder(data){
    //     let cont = $('<div>')
    //         .html(data)
    //         .addClass('')
    //
    //     this.FoldersList.append(cont)
    //
    //     return cont
    // }

    addListMessage(data){
        let msg = $('<div>')
            .html(data.body)
            .addClass('message')

        let cont = $('<div>')
            .html(data.subject )
            .on('click', () => {
                // this.focusOn('message', msg)
                // this.MessageView.html(msg)

                // this.setSideBar(data)
            })
        this.Acc.add(cont,msg)
        return cont
    }

    focusOn(type, obj){

        if(type == 'folder'){
            $('.folder-listing').hide()

        }
        if(type == 'message'){
            $('.message').hide()
        }
        obj.show()
    }

    setSideBar(data){
        this.SideBar.html('')
        let attr = $('<div>')

        for(let key in data.attributes){
            let value = data.attributes[key]
            attr.append(this.renderAttrib(key,value))
        }

        if(data.attachments > 0){
            let button = $('<div>')
                .html('Get Attachments')
                .addClass('btn btn-success')
                .on('click', ()=>{

                })
            this.SideBar.append(button)

        }

        this.SideBar.append(attr)

    }

    renderAttrib(label, info){
        let l = $('<label>')
            .html(label)

        let v = $('<input>')
            .val(info)

        let out = $('<div>')
            .append(l,v)
        return out
    }

}
