
$(function(){
    CS.datePrototypes()
    // CS.initModal()
    CS.initFileManager()
    CS.initSubsheetsModal()
    CS.EventLog.init()
    console.log('started genereal', CS)

    $('.select2').selectize({
        width: '100%'
    })

    $('.addSubsheetNavLink').on('click', function (){
        CS.loadSubsheet($(this).data())
    })

    CS.observer.observe(document, {attributes: false, childList: true, characterData: false, subtree:true});

    $(document).keydown(function (a,b,c){
        if(a.originalEvent.key === 'Shift')
        {
            CS.muteFilter(true);
        }
        // CS.focusFilterInput()
    })

    $(document).keyup(function (a,b,c){
        if(a.originalEvent.key === 'Shift')
        {
            CS.muteFilter(false);
        }
        CS.filterSelectableValues()

    })
    $('.clickFocus').click(function(){
        window.open($(this).attr('src')+'/1', 'new')
    })

    $('.make-sortable').each(function (){
        Sortable.create(this)
    })

    $('.ajaxForm').on('submit', CS.handleFormSubmit);

    $('.activityLogOpen').on('click', ()=> { CS.EventLog.show() } );

    $('.lock-on-submission').on('submit', function(event){
        CS.showSuccess('Saving...', { modal:true, layout: 'center', timeout: 0})
    });

    let setNegative = (i, j) => {
        if ($(j).text().includes('-$'))
            $(j).css('color', 'red')
    }

    $('div[class^="col-"],div[class*=" col-"], .col', '.negativesToRed').each(setNegative)
    $('span', '.negativeSpansToRed').each(setNegative)


});

window.addEventListener("beforeunload", function(event) {

});


