import {createFromInput} from "./html/forms";
import {Map} from "./map.js";

export default class AddBidClass {
    filters
    saveData = {subSheetID: 'AddBid'}
    saving = false;
    cols = ['ProjectName', 'Address', 'Contractor', 'Bidding']

    options

    init(data, options) {
        this.options = {...{
                'context': $('#'+CS.currentContextID()),
                'containerSelector': '.modal-body',
                'container': false,
                'showFilters': true,
                'extraTabs': [],
                'buttons': [],
                'values': []
            }, ...options}

        this.filters = []
        this.container = this.options.container ? this.options.container : $(this.options.containerSelector, this.options.context)
        this.container.html('').addClass('AddBidContainer')

        this.data = data

        this.initGUI()
        console.log('built', this)

    }

    initGUI(){
        let row = $('<div>').addClass('row dataRow')
        let left = $('<div>').addClass('col-3')
        let address= false


        for(let x1 in this.data.tableData){
            let td = this.data.tableData[x1]
            let m = false
            // console.log('hitxx1',td.name,this.options.values, x1)

            td.value = this.options.values[td.name]

            if(td.title === 'Name'){
                m = (o) => {
                    return o.on('change', () => {
                        this.makeFilters(o.val())
                    })
                }
            }
            let inp = createFromInput(td,m)
            left.append(inp)
            if(td.type === 'address'){
                address = $('input',inp)[0]
            }
        }

        left.append(this.options.buttons)
        row.append(left)




        this.filterContainer = $('<div>').addClass('fc')
        this.bidsContainer = $('<div>').addClass('bc')

        let mapElement = $('<div>').html('map').addClass('MapContainer')


        let tabs = [...[
            {title: 'Lookup', content: $('<div>').append(this.filterContainer, this.bidsContainer)},
            {title: 'Map', content: mapElement},
        ],...this.options.extraTabs]

        this.right = $('<div>').addClass('col').html(CS.tabs_html(tabs))
        row.append(this.right)
        this.updateFilters()

        this.container.append(row)

        let markAddress = 'Toronto'

        let map = new Map(mapElement[0])

        map.loadGoogleMapsApi().then((googleMaps)=> {
            map.setGoogleMapsApi(googleMaps, address)
            map.geocode({address: markAddress})
        });


        this.makeFilters(this.options.values['projectName']??'')



    }


    save(callback = false){
        let data = {... this.saveData, ... CS.collectData(this.container)}

        data.mapData = CS.mapData()

        if(this.saving){
            CS.showInfo('Already Saving...')
            return
        }
        let n = CS.showInfo('Saving...')

        this.saving = true

        setTimeout(()=>{
            this.saving = false
        },5000)


        CS.clearTrackedChanges()

        CS.makeCall({
            url: route('subsheet.save'),
            data: JSON.stringify(data),
            contentType: 'application/json'
        }, (r) => {

            n.hide()
            this.saving = false
            if(r.failed === false){

                this.handleResponse(r)
            }else{
                CS.showError(r.errors)
            }

            if(callback) callback(r)
        })

    }

    handleResponse(r){

        // CS.handleResponse(r)
        //
        // this.right.html('')
        //
        //
        // if(r.similars.length > 0){
        //
        //     for (let i in r.similars){
        //         let d = r.similars[i]
        //         let c = $('<div>').addClass('row')
        //             .append($('<div>').addClass('col').html(d.ProjectName))
        //             .append($('<div>').addClass('col').html(d.Address))
        //             .append($('<div>').addClass('col').html(d.DueDate))
        //
        //         this.right.append(c)
        //     }
        //
        //     this.right.prepend(
        //         $('<input type="checkbox" name="force">'),
        //         'Add Anyway',
        //         $('<hr>'),
        //
        //     )
        // }

        if(r.newRowID){

            CS.loadSubsheet({
                colID: 2453,//CS.getCurrentSubsheet().colID,
                rowID: r.newRowID,
            })

            // this.right.html('')
            // let fm = new FileMan(this.right)
            //
            // fm.setPath(r.newPath)
            // fm.setRowID(r.newRowID)
            // fm.init()


        }

    }

    addFilter(text){
        this.filters.push(text)

        this.updateFilters()
        this.makeBidList()
    }

    updateFilters(){

        this.filterContainer.html('')

        $(this.filters).each((k, f) => {
            let o = $('<div>').addClass('badge bg-secondary btn-group')
            let c = $('<span>').addClass('')
                .html(f)

            let x = $('<input>')
                .attr({
                    'type': 'checkbox',
                    'value': f,
                })
                .addClass('form-check-input filter-check')
                .on('click', () => {
                    this.makeBidList()
                })
            o.append(c,x)
            this.filterContainer.append(o)
        })


    }

    makeFilters(text){
        this.filters = []

        $(text.split(' ')).each((k, t) => {
            this.addFilter(t)
        })

        this.updateFilters()

        this.makeBidList()
    }

    makeBidList(){
        let values = []
        $('.filter-check:checked').each((k, o) => {
            values.push($(o).val())
        })

        if(values.length === 0) return

        let words = values.map(function(value,index){return '(?=(.)*?\\b('+value+')\\b)'; }).join('');

        let regex =new RegExp(`${words}((.)+)`,'g');
        let res = this.data.bids.filter((obj) => {

            for(const x of this.cols){
                if(regex.test(obj[x])){
                    return true
                }
            }
        })


        let out = $('<div>')

        $(res).each((k, v) => {

            let row = $('<div>').addClass('row border-bottom-head border-1 border')

            $(this.cols).each((k2, c) => {
                row.append(
                    $('<div>').addClass('col').html(v[c]),

                )
            })
            row.append(
                $('<button>').addClass('btn btn-sm btn-success col-1').html('Attach').on('click', () => {
                    this.saveData.bidID = v['id']
                    // this.saveData.subSheetID = 'update'
                    this.save()
                })
            )

            out.append(row)
        })

        this.bidsContainer.html(out)

    }

}
