import { Crop, RotateLeft } from 'photo-editor/tools';
import { PhotoEditor } from 'photo-editor';
import {makeCall, notif} from "../calcsys";

let modal = false
let modalEl
let photoEditor
let saveFunc
let canvas

let imgID
let origImg

function initPhotoEditor(){
    if(!modal){
        modalEl = $('#CSModal').css('z-index', '9999999')
            .addClass('PhotoEditorContainer')//$('<div>').addClass('modal')
        canvas = $('<canvas>')
        $('.modal-body',modalEl).append(canvas)

        $('.modal-title', modalEl).html('Edit Image')

        // $('body').append(m)

        modal = new Modal(modalEl)

        addBottomButtons()
    }
}

export function startPhotoEditor(img, id, saveFuncc) {
    // let el = $('#')
    imgID = id
    origImg = img
    saveFunc = saveFuncc
    initPhotoEditor()
    photoEditor = new PhotoEditor(canvas[0], {
        tools: {
            crop: Crop,
            rotateLeft: RotateLeft
        },
        sourceType: 'img',
        source: img
    })
    // console.log(el,img, photoEditor)
    photoEditor.addListener("ready", () => {
        photoEditor.toggleTool("crop")
    })

    modal.show()
}

function addBottomButtons(){
    let save = $('<div>').addClass('btn btn-success').html('Save')
        .on('click', function (){
            if(saveFunc) {
                saveFunc(canvas[0].toDataURL())
                modal.hide()
            //
            }
            else CS.saveUpdatedImage()
        })
    let crop = $('<div>').addClass('btn btn-info').html('Apply Crop')
        .on('click', function (){
            photoEditor.tools.crop.applyCrop()

        })
    let rotate = $('<div>').addClass('btn btn-info').html('Rotate')
        .on('click', function (){
            photoEditor.enableTool("rotateLeft")
            photoEditor.enableTool("crop")

        })
    $('.modal-footer', modalEl)
        .html('')
        .append(save,crop,rotate)
}

export function saveUpdatedImage(){
    var dataURL = canvas[0].toDataURL()
    let t = CS.notif('warning', 'Saving...')
    makeCall({
        url: route('updateImage'),
        data: {img: dataURL, id: imgID},
    },function (r){
        t.hide()
        CS.notif('success', 'Saved')
        modal.hide()
        origImg.src = dataURL
    })
}
