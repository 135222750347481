import defaultExport from 'load-google-maps-api';
// import * as loadGoogleMapsApi from 'load-google-maps-api';


// let inputID = '#address'

// let autocomplete, marker, infowindow, map;


class Map {

    _mapElement
    _place
    googleGeocoder = false
    googleMap = false
    googleMapData = false
    googleMapMarker = false
    googleAutocomplete = false
    googleMaps = false;

    constructor(el) {
        this._mapElement = el
    }

    loadGoogleMapsApi() {
        let o = defaultExport({key: import.meta.env.VITE_GOOGLE_API_KEY, libraries: ['places']})
        console.log('LoadGAPI',o)
        return o
    }

    getGoogleMapsApi() {
        return this.googleMaps
    }
    getGoogleMapData() {
        return this.googleMapData
    }

    setGoogleMapsApi(gmapi, obj) {
        this.googleMaps = gmapi
// return
        this.googleGeocoder = new this.googleMaps.Geocoder();

        var inputss = obj ?? document.querySelector('#address');
        this.googleAutocomplete = new this.googleMaps.places.Autocomplete(inputss);

    }


    createMap(center) {

        this.googleMap = new this.googleMaps.Map(this._mapElement, {
            center: center, zoom: 14, disableDefaultUI: true, mapTypeId: this.googleMaps.MapTypeId.ROADMAP
        });

        this.googleMapMarker = new this.googleMaps.Marker({
            map: this.googleMap
        });

    }

    geocode(opts, callback) {
        callback = callback ? callback: (r,s) => { this.handleGeocodeResult(r,s) }
        this.googleGeocoder.geocode(opts, callback )
    }

    handleGeocodeResult(results, status) {
        console.log('handleGeocodeResult', results);
        let lat = results[0].geometry.location.lat();
        let lon = results[0].geometry.location.lng();

        this.createMap({lat: lat, lng: lon})

        this.googleMap.fitBounds(results[0].geometry.viewport);

        this.googleMapMarker.setPosition(results[0].geometry.location);
        this.googleMapMarker.setVisible(true);

        this.googleMaps.event.addListener(this.googleAutocomplete, 'place_changed', (a,b) => {
            this.handlePlaceChanged(a,b)
        })

    }

    handlePlaceChanged(a, b) {
        this.googleMapMarker.setVisible(false);


        var place = this.googleAutocomplete.getPlace();

        // let valid = 0;
        // $(place.address_components).each(function (a, b) {
        //     if (b.types[0] == 'route') valid++
        //     if (b.types[0] == 'street_number') valid++
        // })
        //
        // if (valid < 2) {
        //     alert('Please enter full street address')
        //     $('#address')[0].value = ''
        //     return
        // }
        this.googleMapData = place
        //
        // if (!place.geometry) {
        //     alert('error address');
        // }
        if (place.geometry.viewport) {
            this.googleMap.fitBounds(place.geometry.viewport);
        } else {
            alert('error');
        }

        this.googleMapMarker.setPosition(place.geometry.location);
        this.googleMapMarker.setVisible(true);


        // document.getElementById('latitude').value = place.geometry.location.lat();
        // document.getElementById('longitude').value = place.geometry.location.lng();
        // $('#address').val(place.formatted_address);
        $('#fullAddress').html(place.formatted_address);


        CS.mapData(place)
        this._place = place;

    }
}

export {Map};
