import {currentRow, getCurrentCell, getFilteredData} from "./sheet";

let filterModalObj
let filterModalDialog
let filterActive = false
let currentUID = 0
let filterData = {
    sort: {},
    where: {},
    group: {}
}
let filterMap

let presetFilters = {
    natural: {
        sort: {},
        where: {},
        group: {}
    },
    'sort-date': {
        sort: [["Date",'desc']],
        where: {},
        group: {}
    }
}

export function initFilterModal(){
    filterModalObj = $('#filterModal')[0];
    filterModalDialog = new window.Modal(filterModalObj, {});
    $(filterModalObj).on('show.bs.modal', function (e) {
        // $('.modal-dialog', filterModalObj).css('width', 1000);
        filterActive = true
    });
    $(filterModalObj).on('hide.bs.modal', function (e) {
        filterActive = false
    });

    $('.filterButton').on('click', function (event) {
        $('.sheetFilter').show();
    });

    $('.filterSelect').on('change', function (event) {
        let parent = this;
        if (this.value === 'list') {
            CS.getColValues($(this).data('fid'))
            // $('.fv' + $(parent).data('fid')).hide();
            $('#filterValues' + $(parent).data('fid')).show();

        }else{
            // $('.fv' + $(parent).data('fid')).show();
            $('#filterValues' + $(parent).data('fid')).hide();

        }

        if (this.value === 'btw') {
            $('.fv' + $(parent).data('fid') + '_2').show();

        } else {
            $('.fv' + $(parent).data('fid') + '_2').hide();
        }
    });

    $('.filter-preset').on('click', function () {
        loadPresetFilter(this)
    })

    $('#filterLimit').on('focus', function (){
        filterActive = false
    }).on('blur', function (){
        filterActive = true
    })

    $('.datePicker').datepicker(CS.dpOptions)

    // $('.filterSelect').each(function (){ new SlimSelect({select: this}) })
    //
    // $('#filterForm, #filterFormByDate').on('submit', function (event) {
    //     $('.sheetFilter').hide()
    //     let n = CS.showInfo('Loading ... ')
    //     event.preventDefault()
    //
    //     CS.getFilteredData()
    //
    // });


    $('.applyFilter').on('click', function (){
        let opts = getFilterOptions(getCurrentCell().uid)
        console.log('-->',opts)
        if(opts.sort){
            updateFilter('sort',[getCurrentCell().name,opts.sort])
        }
        if(opts.groupBy){
            updateFilter('group',[getCurrentCell().name,opts.groupBy])
        }
        if(opts.operation === 'list') {
            updateFilter('where',[getCurrentCell().name,opts.operation,opts.distinct])

        }
        if(opts.operation === 'btw') {
            updateFilter('where',[getCurrentCell().name,opts.operation,opts.val,opts.val_2])

        }else if(opts.operation){
            updateFilter('where',[getCurrentCell().name,opts.operation,opts.val])
        }

        CS.getFilteredData()

        $('.currentFiltersContainer').html(renderFilterList())
    })

}

// export function

export function focusFilter(uid){
    currentUID = uid
    $('.filterRows').hide();

    $('.uid' + uid).show();

    // console.log('FocusFilter', uid, filterMap[uid])
    if(filterMap)
        $('[name="sort"][value="'+filterMap[uid].sort+'"]','.uid' + uid).prop('checked', true);
    else
        $('[name="sort"][value=""]','.uid' + uid).prop('checked', true);
}

export function loadPresetFilter(obj){
    let name = $(obj).data('name')

    if(name === 'sort'){
        let col = $(obj).data('column')
        let direction = $(obj).data('direction')
        filterData['sort'] = [[col,direction]]
    }else{

        let newFilters = presetFilters[name]

        setFilter(newFilters)
    }

    CS.getFilteredData()
}

export function getFilterOptions(uid){
    let opts = $('input, select', '.uid' + uid).serializeArray()
    return CS.cleanupSerial(opts)
}

export function getColValues(uid)
{
    let fv = $('#filterValues' + uid)
    if ($(fv).html() === '') {
        $(fv).html('Loading...')
        CS.makeCall({
            url: route('page.loadColumnFilter', uid),
            callback: function (data) {
                let d = data
                fv.html('')
                $(d).each(function () {
                    // fv.append("<div><input type='checkbox' name='distinct[" + uid + "][]' value='" + (this.val) + "' >" + (this.val) + "</div>");
                    fv.append(CS.checkBox_html("distinct", this.UID, this.Name))
                });

            }
        });
    }
}

export function updateFilter(op, data, add = true){
    let t = arrayToObject(filterData[op])
    if(add){
        if(op === 'where' && data[1] === 'list' && (data[2] === '' || data[2] === null || data[2] === undefined)) return
        if(op === 'group' && data[1] === 'false') return
        if(data[2] && (data[2] === '' || data[2] === [])) return
        t[data[0]+data[1]] = data
    }
    else{
        delete t[data[0]+data[1]]
    }

    filterData[op] = objectToArray(t)


}

export function objectToArray(obj){
    let n = []
    for(let x in obj){
        let v = obj[x]
        n.push(v)
    }
    return n
}
export function arrayToObject(arr){
    let n = {}
    for(let x in arr){
        let v = arr[x]
        n[v[0]+v[1]] = v
    }
    return n
}
export function clearDuplicates(arr){
    return objectToArray(arrayToObject(arr))
}

export function setFilter(data, filtermap){
    if(!data) return;
    $('#filterLimit').val(data.limit)
    filterData = {...filterData, ... data}
    for(let x in filterData){
        filterData[x] = clearDuplicates(filterData[x])
    }

    if(filtermap) filterMap = filtermap
    // filterData = data
}

export function getFilter(){
    let out = filterData
    // out.sort = filterData.sort.values()
    // out.where = filterData.where.values()
    // out.group = filterData.group.values()
    return out
}

export function updateFilterlistHtml(){
    $('.currentFiltersContainer').html(renderFilterList())
}

export function openFilterModal(cell){
    if(filterMuted) return

    $('.filterSelect.filterSel-'+cell.uid).val('LIKE');
    focusFilter(cell.uid)

    updateFilterlistHtml()

    getColValues(cell.uid)
    $('.filterSelect').trigger('change')
    filterModalDialog.show()
}

let filterMuted = false;
export function muteFilter(b){
    filterMuted = b;
}

export function renderFilterList(){
    if(!CS.mySpreadSheet) return
    if(!filterMap && filterData.sort.length === 0 && filterData.where.length === 0) return

    let out = $('<div>')
    let sortBox = $('<div>').append('<label>Sorting</label>')

    $(filterData.sort).each(function (index, obj){

        let item = createFilterItem(obj[0],obj[1],function (){
            CS.updateFilter('sort', obj, false)
            updateFilterlistHtml()
            CS.getFilteredData()
        })
        sortBox.append(item)
    })

    let whereBox = $('<div>').append('<label>Filtering</label>')
    $(filterData.where).each(function (index, obj){
        let titleCell = CS.titleRow().cells.find(o => o.name === obj[0])
        if(!titleCell) {
            console.log('Col not found', obj)
            return
        }
        let subTitle = $('<div>')
        subTitle.append($('<div>').html(obj[1]))
        let sub1 = Array.isArray(obj[2]) ? obj[2].join('<br>') : obj[2]

        subTitle.append($('<div>').html(sub1))
        let item = createFilterItem(titleCell.text, subTitle, function (){
            CS.updateFilter('where', obj, false)
            updateFilterlistHtml()
            CS.getFilteredData()
        })

        whereBox.append(item)
    })

    if(filterData.sort.length > 0)
        out.append(sortBox)
    if(filterData.where.length > 0)
        out.append(whereBox)

    return out
}

function createFilterItem(title, subTitle, toRemove){
    let item = $('<div>').addClass('border border-1 border-dark px-2 row')
    let itemTitle = $('<div>').html(title).addClass('col-3')
    let itemSubTitle = $('<div>').html(subTitle).addClass('col')
    let removeBtn = $('<span>')
        .addClass('btn btn-sm btn-danger col-1 align-content-center')
        .html('X')
        .on('click', toRemove)
    return item.append(itemTitle,itemSubTitle, removeBtn)
}

export function filterSelectableValues(){
    if(filterActive){
        let rows = $('.filter-value-container div');
        let val = $.trim($('.fv'+currentUID).val()).replace(/ +/g, ' ').toLowerCase();
        rows.show().filter(function() {
            var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
            // console.log(text);
            return !~text.indexOf(val);
        }).hide();
    }
}

export function focusFilterInput(){
    if(filterActive) {
        $('.fv'+currentUID).focus()
    }

}
