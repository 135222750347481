import {uniqueId} from "lodash";

export default class MiniTableClass {
    layout
    data = []

    sortableObj = null
    rowContainer
    mainContainer
    _settings = {
        addRow: true,
        addGroup: false,
        id: uniqueId(),
    }

    constructor(layout, data, s ={}){
        this.layout = layout
        this.data = data
        this.rowContainer = $('<div>').addClass('dataContainer')
        this._settings = {...this._settings, ...s}


        if(!data || data.length === 0) {
            // if(this._settings.addRow) this.addRow()
            // if(this._settings.addGroup) this.addRow()
        }
        this.mainContainer = $('<div>').addClass('mx-2 MiniTableContainer')
            .append(this.createHeader())
            .append(this.rowContainer)
            .append(this.createFooter())
            .attr('id', this._settings.id)

        this.sortableObj = $(this.rowContainer[0]).sortable( {
            animation: 150,
            handle: '.reorderHandle',
            start: (a,b) => { },
            stop: (a,b) => {
                CS.updateSums()
            }
        })

        console.log('MT',this)
    }

    getHTML(){

        return this.mainContainer
    }


    createHeader(){
        let out = $('<div>')
            .addClass('rowContainer row sticky-top')

        $(this.layout).each((k, o) => {
            let cell = $('<div>')
                .append($('<div>').html(o.title))
                .addClass(o.class)
            if(o.subTitle)
                cell.append($('<div>').addClass('badge bg-dark').html(o.subTitle))

            out.append(cell)
        })

        return out;
    }



    createFooter(){
        let out = $('<div>')
            .addClass('rowContainer sticky-bottom footer-row bg-white')

        let addRowBtn = $('<span>')
            .addClass('btn-add-row row')
            .on('click', ()=> { this.addRow()})
            .html('<i class="fa-solid fa-plus col">Add Row?</i>')


        // let addGroupBtn = $('<span>')
        //     .addClass('btn-add-row row')
        //     .on('click', ()=> { this.addGroup()})
        //     .html('<i class="fa-solid fa-plus col">Add Group</i>')

        let sumRow = $('<div>')
            .addClass('sumRowContainer row border-top border-dark ')


        $(this.layout).each( (k, o) => {
            let cell = $('<div>')
                // .append($('<div>').html('.'))
                .addClass(o.class)
            if(o.type === 'currency') {
                let x = $('<span>').addClass('subTotal total_'+o.name)
                cell.append(x)
            }
            sumRow.append(cell)

        })

        out.append(sumRow)
        if(this._settings.addRow) out.append(addRowBtn)
        // if(this._settings.addGroup) out.append(addGroupBtn)

        return out;
    }

    // addGroup(){
    //
    //     this.data.push({
    //         type: 'group',
    //         name: 'Group',
    //         rows: []
    //     })
    // }
    addRow(d={}){
        this.rowContainer.append(this.createRow(d))

        // if(this.sortableObj)
        //     this.sortableObj.refresh()
    }

    // createGroup(data){
    //     let out = $('<div>')
    //         .addClass('rowContainer row groupRow')
    //
    //     let title = $('<div>').html(data.name)
    //     out.append(title)
    //
    //
    //     return out
    // }
    createRow(data){
        let out = $('<div>')
            .addClass('rowContainer row dataRow')

        $(this.layout).each(function (){
            let c = CS.createCol(data, this)
            out.append(c)
        })


        return out
    }
}
