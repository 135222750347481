
let fileManagerObj = false;
let offC = false;
let fileManagerStatus = false;
let side = 'right';

export function initFileManagerBox(obj){
    let out = fileUploadForm_html([])

    out = CS.attachFileHandle(out, (xhr)=>{
        let d = xhr.responseJSON
        CS.addUploadedFiles(d.newImages)

    })

    obj.append(out)
}
export function initFileManager(){
    fileManagerObj = $('#fileManager')[0];

    $(fileManagerObj).on('hidden.bs.offcanvas', function () {
        fileManagerStatus = false;
    }).on('shown.bs.offcanvas', function () {
        fileManagerStatus = true;
    });

    offC = new Offcanvas(fileManagerObj);
    $('.offcanvas').width(250)

    $('#fileManager .switchViews').on('click', function (){
        CS.fileManagerSwitchViews();
    })
    $('#fileManager .switchSides').on('click', function (){
        fileManagerSwitchSides();
    })

    CS.fileManagerSwitchViews();
    // $('#fileManager .galleryContainer').hide();

    $('#fileManager form').on('submit',(function(e) {
        e.preventDefault();
        let n = CS.showInfo('Uploading...')
        var formData = new FormData(this);

        $.ajax({
            type:'POST',
            url: route('files.saveCell') ,
            data:formData,
            cache:false,
            contentType: false,
            processData: false,
            success:function(data){
                n.hide()
                resetGallery()

            },
            error: function(data){
                CS.showError('An error occurred; please try again')
                console.log('Error Response:',data);
            }
        });
    }));

    $("#fileManager .drag-drop").on("change", function() {
        $("#fileManager form").submit();
        $(this).val('')
    });










    //
    // $('#fileManager .drag-drop').on("change", function (e) {
    //
    //     const files = e.target.files;
    //     console.log("file!!: ", files);
    //
    //     if (files?.length) {
    //         // let data = $('#fileManager form').serializeArray()
    //
    //         const file = files[0];
    //         console.log("uploaded file: ", file);
    //         if (
    //             file.type.toLowerCase() === "image/heic" ||
    //             file.name.toLowerCase().includes(".heic")
    //         ) {
    //
    //             CS.showSuccess('Converting File, please wait...',{ modal: true });
    //
    //             heic2any({blob: file, toType: "image/jpg", quality: 1}).then(
    //                 (newImage) => {
    //                     const url = URL.createObjectURL(newImage);
    //                     CS.showInfo('Saving File...',{ killer: true });
    //
    //                     // data.append('upload','newImage');
    //                     data['uploadAlt'] = newImage
    //                     // console.log("data:", data);
    //                     CS.ajaxCall({
    //                         url: route('files.saveCell') ,
    //                         data: data,
    //                         type: "POST",
    //                         cache: false,
    //                         contentType: false,
    //                         processData: false,
    //                         dataType: false,
    //                     }, function (c){
    //                         console.log('im baaack!',c)
    //                     })
    //                     // $.ajax({
    //                     //     url: "{{ route('files.saveCell') }}",
    //                     //     data: data,
    //                     //     type: "POST",
    //                     //     cache: false,
    //                     //     contentType: false,
    //                     //     processData: false,
    //                     //     dataType: false,
    //                     //     success: function (data) {
    //                     //         location.reload();
    //                     //     },
    //                     //     error: function (e) {
    //                     //         alert("error while trying to upload file!");
    //                     //     }
    //                     // });
    //                 }
    //             );
    //
    //
    //         } else {
    //             let d=new FormData($('#fileManager form')[0])
    //             console.debug(d.values())
    //             CS.ajaxCall({
    //                 url: route('files.saveCell'),
    //                 data: d,
    //                 // type: "POST",
    //                 // cache: false,
    //                 contentType: false,
    //                 processData: false,
    //                 // dataType: false,
    //             }, function (c){
    //                 console.log('im baaack!',c)
    //             })
    //             // $('#fileManager .content-drag-drop').trigger("submit");
    //         }
    //     }
    //
    //     // $('.btn-info').trigger("click");
    // });
}
let galleries = [];
let view = 'list'
let currentUID = 0;
let currentRowID = 0;
let currentColID = 0;
export function openFileManager(){
    offC.show()
}
export function loadIntoFileManager(opts = []){
    offC.show()
    if(opts.rowID){

        $('#fileManager #rowID').val(opts.rowID);
        $('#fileManager #colID').val(opts.colID);
        currentUID = opts.rowID+'_'+opts.colID;
        $('.galleryItem, .listItem').hide();
        currentRowID = opts.rowID ;
        currentColID = opts.colID ;
    }
    if(galleries[currentUID]){
        $('.list'+currentUID+', .gallery'+currentUID).show();
    }else{

        galleries[currentUID] = true;

        CS.ajaxCall({
            url: route('files.cellFiles'),
            data: {rowID: currentRowID, colID: currentColID }
        }, function (response){
            addGallery(response.files,response.uid)
            addList(response.files,response.uid)
        })

    }

}

export function resetGallery(){
    CS.showInfo('Reloading Images')
    $('.list'+currentUID+', .gallery'+currentUID).remove();
    galleries[currentUID] = false
    loadIntoFileManager()
}
export function addGallery(files,uid){
    let g = gallery_html(uid);
    $(files).each(function (){
        if(this.deleted_at === null)
            g.append(galleryItem_html(this.id))
    })

    $('.fileManagerContent .galleryContainer').append(g);
}

export function addList(files,uid){
    let g = list_html(uid);
    $(files).each(function (){
        g.append(listItem_html(
            route('files.download',this.id),
            this.name,route('files.delete', this.id),
            this.deleted_at !== null
        ))
    })
console.log('==>', files)
    $('.fileManagerContent .listContainer').append(g);
}

export function isOpenFileManager(){
    return fileManagerStatus;
}
export function hideFileManager(){
    offC.hide()
}
export function fileManagerSwitchSides(){
        if(side == 'left') {
            $('#fileManager').removeClass('offcanvas-start').addClass('offcanvas-end')
            side = 'right'
        }
        else if(side == 'right'){
            $('#fileManager').removeClass('offcanvas-end').addClass('offcanvas-start')
            side = 'left'
        }



}
export function fileManagerSwitchViews(){
    if(view == 'gallery'){
        $('.galleryContainer').hide();
        $('.listContainer').show();
        $('.offcanvas').animate({width: "250px"},)
        view = 'list';
    }else{
        view = 'gallery'
        $('.offcanvas').animate({width: "600px"},)

        $('.galleryContainer').show();
        $('.listContainer').hide();
    }
}

function galleryItem_html(id){
    let g = $('<div>')
        .addClass('')
        .append(
            $('<img>').attr('src', '/images/'+id)
                .on('dblclick', function (){
                    CS.startPhotoEditor(this,id)
                })
        );

    return g;
}
function gallery_html(id){
    let g = $('<div>').addClass('galleryItem gallery'+id);


    return g;
}




function list_html(id){
    let g = $('<div>').addClass('listItem list'+id);

    return g;
}

function listItem_html(src,name,delSrc,deleted=false){
    let g = $('<div>')
        .addClass('linkWrapper p-1 m-1')
        .append(
            $('<i class="fa-solid fa-file-image pe-1"></i>')
        )
        .append(
            $('<a>')
                .attr('href', src)
                .addClass('link')
                .html(name)
        )
        if(!deleted)
            g.append(
            $('<a>')
                .attr('data-href', delSrc)
                .attr('href', '#')
                .on('click', function (){
                    if(confirm('Are you sure you want to delete this file?')){
                        CS.makeCall({
                            url: $(this).data('href'),
                            method: 'GET'
                        }, function (r){
                            resetGallery()
                        })
                    }
                })
                .append("<i class=\"fa-regular fa-trash-can\"></i>")

        );
        else
            g.addClass('border border-danger')

    return g;
}


export function fileUploadForm_html(fields, submitRoute){
    if(!submitRoute) submitRoute = route('files.saveCell')
    let dataForm = CS.createForm({
        action: submitRoute,
        fields: fields,
        files: true
    })

    let uid = nanoid()
    let inp = $('<input class="drag-drop" type="file"  name="upload[]" multiple >')
        .on('change', function(){
            let form = $(this).parents('form:first');
            form.trigger('submit')

            // dataForm.append($(this))

            // handleFileUpload(dataForm)
        })
        .attr('id', uid)
    let obj = $(`
        <div class="col content-drag-drop">

            <div class="drag-drop-in">
                <div class="in-content" >
                    <h5>Drag & Drop<br>OR<br>Click to select<br>your files here</h5>
                </div>
            </div>
        </div>
    `)
    $(obj).prepend(inp)
    $(dataForm).append(obj)

    var csrf = document.querySelector('meta[name="csrf-token"]').content;
    $(dataForm).append($('<input name="_token" type="hidden">').val(csrf))
    // handleFileUpload(dataForm)

    return dataForm
}


export class FileMan {
    ctx
    path
    fileList
    files
    gui = {}
    importedFiles
    mode = false
    displayPath = '/'
    displayFiles = []
    rowID = false

    constructor(ctx) {
        this.ctx = ctx

        this.renderGUI()
    }

    init(){
        this.importedFiles = CS.getCurrentSubsheet().data.files
        this._fetch()
    }

    load(){
        this.renderFileList()
        this.renderFolderList()
        // this.renderFileList()
    }

    setRowID(x){
        this.rowID = x
    }

    setPath(x){
        this.path = x
    }

    renderGUI(){
        this.gui.main = $('<div>').addClass('FileManagerContainer row')

        this.gui.left = $('<div>').addClass('col-3')
        this.gui.middle = $('<div>').addClass('col').html(CS.renderSpinner())
        this.gui.right = $('<div>').addClass('col-2').html('')

        // left.append(this.renderFileList())

        this.gui.main.append(this.gui.left,this.gui.middle, this.gui.right)

        this.ctx.append(this.gui.main)

    }

    createFolderItem(name, children){
        let out = $('<div>').addClass('row border-bottom border-1 m-1')
        let icon = $('<div>').addClass('col-1')
        // let name = $('<div>').addClass('')
        let title = $('<div>').addClass('title col-10 text-truncate').html(name) //.append(name)

        icon.html('<i class="fa-solid fa-folder"></i>')

        title.on('click', () => {
            // this.renderProps(item)
            this.displayFiles = children
            this.renderFileList()
        })

        let sub = $('<div>').addClass('')

        $(children).each((k,f) => {
            if(f.type === 'dir')
                sub.append(this.createFolderItem(f.name,f.children))

        })

        out.append(icon, title, sub)

        return out
    }

    renderFolderList(){
        let out = $('<div>').addClass('folderList ')

        out.append(this.createFolderItem(this.path,this.files))


        this.gui.folderList = out

        this.gui.left.html(out)
        this.gui.left.append(
            $('<div>').addClass('btn btn-warning').html('Clear cache & reload').on('click', () => {
                this.gui.middle.html('Reloading')
                this.gui.left.html('')
                this._fetch(true)
            })
        )
    }

    renderFileList(){
        let out = $('<div>').addClass('fileList ')

        $(this.displayFiles).each((k,f) => {
            if(f.type !== 'file')
                return


            let item = $('<div>').addClass('hstack gap-1 border-bottom border-1 m-1')

            let icon = $('<div>').addClass('')
                .html('<i class="fa-solid fa-file"></i>')

            let title = $('<div>').addClass('title ')
                .html(f.name) //.append(name)

            if(this.importedFiles[f.meta.id]){
                // title.append('Proced!')
                item.addClass('bg-success')
            }

            if(f.mime === 'application/pdf'){
                icon.html('<i class="fa-solid fa-file-pdf"></i>')

            }
            let size = $('<div>').html(
                this.getReadableFileSizeString(f.size)
                // f.meta.id
            ).addClass('text-end ms-auto')


            item.on('click', () => {
                this.renderProps(f)
                $('.active', out).removeClass('active')
                item.addClass('active')
            })

            item.append(icon, title, size)
            out.append(item)
        })


  //       `<a href="#" class="list-group-item list-group-item-action active" aria-current="true">
  //   <div class="d-flex w-100 justify-content-between">
  //     <h5 class="mb-1">List group item heading</h5>
  //     <small>3 days ago</small>
  //   </div>
  //   <p class="mb-1">Some placeholder content in a paragraph.</p>
  //   <small>And some small print.</small>
  // </a>`
        // $(files).each((k, file) => {
        //     let x = $('<div>').html(file.n)
        //
        //     this.fileList.append(x)
        // })

        this.gui.fileList = out

        let upload = CS.fileUploadForm_html([
            // { name: 'rowID', value: this.rowID},
            // { name: 'sheetID', value: CS.getCurrentSubsheet().sheetID},
        ], route('uploadBidFile'))

        this.gui.middle.html('').append(out, upload)
    }

    renderProps(item){
        let out = $('<div>').addClass('props ')


        if(item.type === 'file'){
            // let view = $('<div>').addClass('btn btn-sm btn-info')
            //     .html('View')
            //     .on('click', () =>{
            //         // window.open()
            //     })
            //
            // out.append(view)
            let proc = '';
            // if(this.importedFiles[item.meta.id]) {
                out.append($('<div>').addClass('btn btn-sm btn-info')
                    .html('Load')
                    .on('click', () =>{
                        this.renderPDFPages(this.importedFiles[item.meta.id])
                    }))
            // }else{
                let inp = $('<input>').attr({
                    'placeholder': '2-5 OR 3,5,7',
                })
                out.append('<hr>',inp,
                    $('<div>').addClass('btn btn-sm btn-info')
                    .html('Process')
                    .on('click', () =>{
                        let c = CS.getCurrentSubsheet()
                        let i
                        if(this.importedFiles[item.meta.id])
                            i = this.importedFiles[item.meta.id].id
                        CS.ajaxCall({
                            url: route('files.procPDF'),
                            data: {...item,...
                                    {'colID': c.colID, 'rowID': c.rowID,'parms': inp.val(),'fileID': i}
                            }
                        }, (r) => {

                            CS.EventLog.show()

                        })
                    }))



            // }

            this.gui.fileList = $('<div>').addClass('fileList ')
                .append(CS.Drawings.renderPageList())

            out.append('<hr>', this.gui.fileList)
        }

        this.gui.props = out

        this.gui.right.html('').append(out)
    }




    _fetch(clear = 0){
        // ajax for files
        CS.ajaxCall({
            url: route('gDrive'),
            data: { path: this.path , clear: clear}
        }, (response) => {

            this.files = response
            this.displayFiles = response

            this.load()

        })

    }

    getReadableFileSizeString(fileSizeInBytes) {
        var i = -1;
        var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
            fileSizeInBytes /= 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    }


    renderPDFPages(data){
        this.imgUID = data.id
        let main = $('<div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="false">')
        let inner = $('<div class="carousel-inner">')

        $(data.data.pages).each((k,x) => {
            let i = $('<div class="carousel-item">')
            if(k === 0) i.addClass('active')
            let img = $('<img>').attr('src', route('Drawings.loadImg',[data.id,x]))
                .on('load', (a,b) => {

                })
            let cap = $('<div class="carousel-caption d-md-block">')
            let count = $('<div>').addClass('btn btn-info').html(x + '/' + data.data.totalPages)
            let btn = $('<div>').addClass('btn btn-success').html('IMPORT')
                .on('click', ()=> {
                    CS.Drawings.addPage(x,data.id)
                    this.gui.fileList.append(CS.Drawings.renderPageList())
                })
            cap.append(count,btn)
            i.append(img,cap)
            inner.append(i)

        })



        inner.append(`
<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>`)

        main.append(inner)

        this.gui.middle.html(main)

    }

    setMode(m){
        this.mode = m
    }
}
